import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

//Images
import profile from "assets/img/faces/shea.png";

// Card Stuff
import styles from "assets/jss/material-kit-react/views/profilePage.js";

// Custom values
import Typewriter from "typewriter-effect";

// Supporting "ProfilePage" Components
import About from "views/ProfilePage/About.js";
import ProductionSkills from "views/ProfilePage/ProductionSkills.js";
import HomeLab from "views/ProfilePage/HomeLab.js";
import SectionCarousel from "views/ProfilePage/SectionCarousel.js";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );

  return (
    <div>
      <Header
        color="transparent"
        brand="Shea Hartley"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Parallax small image={require("assets/img/patagonia.png")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h1 className={classes.title}>Shea Hartley</h1>
                    <br></br>
                    <div className="text-center typewriter-line">
                      <span className="mr-1">
                        <Typewriter
                          options={{
                            strings: [
                              "MACHINE LEARNING",
                              "SOFTWARE",
                              "DEVOPS",
                              "SYSTEMS",
                            ],
                            autoStart: true,
                            loop: true,
                          }}
                        />
                      </span>
                      <span>&nbsp;ENGINEER</span>
                    </div>
                    <br></br>
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-github"} />
                    </Button>
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-linkedin"} />
                    </Button>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
              <h4>
                One part data scientist, nine parts engineer. I specialize in
                building analytical systems that handle large quantites of
                streaming data. I lead my teams by example, teaching test
                driven develpment using a DevOps heavy framework to ensure my
                organization and people exceed all expectations.
              </h4>
            </div>
            <div className={classes.description}>
              <h4>Want to work together? Reach out to me via Linkedin.</h4>
            </div>
            <br></br>

            <ProductionSkills />

            <br></br>

            <HomeLab />

            <br></br>

            <SectionCarousel />

            <br></br>

            <About />

            {/* <br></br>

            <NerdBlog /> */}

            <br></br>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
