import React, { useState } from "react";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import classNames from "classnames";

import me_monet_and_wat from "assets/img/me_monet_and_wat.png";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

export default function About() {
  const classes = useStyles();

  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );

  // Values for button showing ship names
  const [shipName, setButtonText] = useState(
    "Click to See an Epic Spacecraft Name"
  );

  // Set the button color
  const [buttonColor, setButtonColor] = useState("info");

  var shipNameList = [
    "So Much For Subtlety",
    "Little Rascal",
    "Unfortunate Conflict Of Evidence",
    "Just Read The Instructions",
    "Flexible Demeanour",
    "Of Course I Still Love You",
    "Limiting Factor",
    "Gunboat Diplomat",
    "Size Isn't Everything",
    "Congenital Optimist",
    "Sweet and Full of Grace",
    "Death And Gravity",
    "Ethics Gradient",
    "Honest Mistake",
    "Serious Callers Only",
    "Fate Amenable To Change",
    "Very Little Gravitas Indeed",
    "Problem Child",
    "It's Character Forming",
    "Killing Time",
    "Quietly Confident",
    "Experiencing A Significant Gravitas Shortfall",
    "Don't Try This At Home",
    "Now We Try It My Way",
    "Falling Outside the Normal Moral Constraints",
    "Mistake Not...",
    "Smile Tolerantly",
  ];

  function getRandom(list) {
    return list[Math.floor(Math.random() * list.length)];
  }

  return (
    <div>
      <GridContainer justify="center">
        <h3 className={classes.title}>About</h3>
      </GridContainer>
      <div class="container">
        <div class="row">
          <div class="col-4">
            <div class="card c">
              <div class="top-sec">
                <img
                  src={me_monet_and_wat}
                  alt="..."
                  className={imageClasses}
                />
              </div>
              <div class="bottom-sec">
                <div className={classes.description}>
                  <h4>
                    Thats me at Angkor Wat, seemingly face to face with a giant,
                    well, face. The image has been modified using a generative
                    adversarial network (GAN) to make the image appear in the
                    style of Claude Monet.
                  </h4>
                  <h4>
                    When I am not outdoors I am either coding for fun or reading
                    a book. Iain Banks is my favorite. Lets bring about the
                    singlularity already. When I travel, I go to remote places
                    that are a bit off the beaten path - less people and more
                    nature. Hard to beat good bourbon and a cigar in the ocean
                    or mountains. We will be best friends if you get what the
                    below button references.
                  </h4>
                  <div></div>
                  <Button
                    color={buttonColor}
                    size="lg"
                    onClick={() => {
                      setButtonColor("warning");
                      setButtonText(
                        "Contacting the Contact Minds for a ship name"
                      );
                      setTimeout(() => {
                        setButtonText(() => getRandom(shipNameList));
                        setButtonColor("info");
                      }, 1200);
                    }}
                  >
                    {shipName}
                  </Button>
                </div>
                <br></br>
              </div>
            </div>
          </div>
          <div class="col-8"></div>
        </div>
      </div>
    </div>
  );
}
