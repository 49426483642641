import React from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Tooltip from "@material-ui/core/Tooltip";

//Images
import khyros_ui from "assets/img/khyros_ui_image.png";
import khyros_find from "assets/img/khyros_find.png";
import khyros_compare from "assets/img/khyros_compare.png";
import khyros_devops from "assets/img/khyros_devops.png";

// Card Stuff
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import Card from "components/Card/Card.js";

// react component for creating beautiful carousel
import Carousel from "react-slick";

const useStyles = makeStyles(styles);

export default function SectionCarousel() {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };
  return (
    <div>
      <GridContainer justify="center">
        <Tooltip
          id="khyros"
          title="Click to go to the Khyros company homepage"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <a
            href="https://www.khyros.ai"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h3 className={classes.title}>
              Khyros Co-Founder - Machine Learning and DevOps Engineer
            </h3>
          </a>
        </Tooltip>
      </GridContainer>

      <Card>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10} className={classes.marginAuto}>
            <Card carousel>
              <Carousel {...settings}>
                <div>
                  <div className="slick-caption">
                    <h3 className="carousel">Khyros User Interface</h3>
                  </div>
                  <img
                    src={khyros_ui}
                    alt="First slide"
                    className="slick-image"
                  />
                </div>
                <div>
                  <img
                    src={khyros_find}
                    alt="Second slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h3 className="carousel">Khyros Pattern Identification</h3>
                  </div>
                </div>
                <div>
                  <img
                    src={khyros_compare}
                    alt="Third slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h3 className="carousel">
                      Khyros Compare Multiple Datasets
                    </h3>
                  </div>
                </div>
                <div>
                  <img
                    src={khyros_devops}
                    alt="Fourth slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h3 className="carousel">Khyros DevOps</h3>
                  </div>
                </div>
              </Carousel>
            </Card>
          </GridItem>
        </GridContainer>
      </Card>
    </div>
  );
}
