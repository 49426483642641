import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";

//Images
import python_logo from "assets/img/python_logo.jpg";
import elastic_logo from "assets/img/elastic_logo.png";
import nifi_logo from "assets/img/nifi_logo.png";
import aws_logo from "assets/img/aws_logo.png";
import js_logo from "assets/img/js_logo.png";
import ts_logo from "assets/img/ts_logo.png";
import react_logo from "assets/img/react_logo.png";
import trello_logo from "assets/img/trello_logo.png";
import jira_logo from "assets/img/jira_logo.png";
import ms_project_logo from "assets/img/ms_project_logo.jpeg";
import docker_logo from "assets/img/docker_logo.png";
import cdk_logo from "assets/img/cdk_logo.png";
import dgraph_logo from "assets/img/dgraph_logo.png";
import mongodb_logo from "assets/img/mongodb_logo.jpeg";
import redis_logo from "assets/img/redis_logo.png";

// Card Stuff
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

const useStyles = makeStyles(styles);

export default function ProductionSkills() {
  const classes = useStyles();
  return (
    <div>
      <GridContainer justify="center">
        <h3 className={classes.title}>
          What I Have Used for Productions Systems
        </h3>
      </GridContainer>

      <Card>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} className={classes.navWrapper}>
            <NavPills
              alignCenter
              color="info"
              tabs={[
                {
                  tabButton: "Machine Learning",
                  tabContent: (
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={10}>
                        <Python />
                      </GridItem>
                    </GridContainer>
                  ),
                },
                {
                  tabButton: "Full Stack Tools",
                  tabContent: (
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={10}>
                        <FullStack />
                      </GridItem>
                    </GridContainer>
                  ),
                },
                {
                  tabButton: "User Interface",
                  tabContent: (
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={10}>
                        <Viz />
                      </GridItem>
                    </GridContainer>
                  ),
                },
                {
                  tabButton: "Databases",
                  tabContent: (
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={10}>
                        <Databases />
                      </GridItem>
                    </GridContainer>
                  ),
                },
                {
                  tabButton: "Systems Engineering",
                  tabContent: (
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={10}>
                        <Systems />
                      </GridItem>
                    </GridContainer>
                  ),
                },
              ]}
            />
          </GridItem>
        </GridContainer>
      </Card>
    </div>
  );
}

function Viz() {
  const classes = useStyles();
  // const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <Card>
      <CardHeader color="info" className={classes.cardHeader}>
        <h4 className="card-header-line">User Interface</h4>
      </CardHeader>
      <CardBody>
        <row>
          <div className="python-line">
            <div></div>
            <a
              href="https://www.javascript.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={js_logo}
                alt="..."
                className={imageClasses}
                width="120"
                height="120"
              />
            </a>
            <a
              href="https://reactjs.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={react_logo}
                alt="..."
                className={imageClasses}
                width="120"
                height="120"
              />
            </a>
            <a
              href="https://www.typescriptlang.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={ts_logo}
                alt="..."
                className={imageClasses}
                width="120"
                height="120"
              />
            </a>
            <div></div>
          </div>
        </row>
      </CardBody>
      <CardFooter className={classes.cardHeader}>
        <p>This website was built with these tools.</p>
      </CardFooter>
    </Card>
  );
}

function Python() {
  const classes = useStyles();
  // const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <Card xs={12} sm={8} md={8}>
      <CardHeader color="info" className={classes.cardHeader}>
        <h4 className="card-header-line">
          Machine Learning with Python
        </h4>
      </CardHeader>
      <CardBody>
        <row>
          <div className="python-line">
            <div></div>
            <a
              href="https://www.python.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={python_logo}
                alt="..."
                className={imageClasses}
                width="120"
                height="120"
              />
            </a>
            <div className="container">
              <a
                href="https://keras.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4>Keras</h4>
              </a>
              <a
                href="https://www.tensorflow.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4>Tensorflow</h4>
              </a>
              <a
                href="https://scikit-learn.org/stable"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4>Sckit-Learn</h4>
              </a>
            </div>
            <div className="container">
              <a
                href="https://docs.pytest.org/en/stable/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4>Pytest</h4>
              </a>
              <a
                href="https://fastapi.tiangolo.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4>FastAPI</h4>
              </a>
              <a
                href="https://flask.palletsprojects.com/en/1.1.x/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4>Flask</h4>
              </a>
            </div>
            <div className="container">
              <a
                href="https://www.scipy.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4>SciPy</h4>
              </a>
              <a
                href="https://numpy.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4>Numpy</h4>
              </a>
              <a
                href="https://pandas.pydata.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4>Pandas</h4>
              </a>
            </div>
            <div></div>
          </div>
        </row>
      </CardBody>
      <CardFooter className={classes.cardHeader}>
        <p>If it's Python, I've done it. Well, a lot of it.</p>
      </CardFooter>
    </Card>
  );
}

function Systems() {
  const classes = useStyles();
  // const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <Card>
      <CardHeader color="info" className={classes.cardHeader}>
        <h4 className="card-header-line">Systems Engineering</h4>
      </CardHeader>
      <CardBody>
        <row>
          <div className="python-line">
            <div></div>
            <a
              href="https://www.atlassian.com/software/jira"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={jira_logo}
                alt="..."
                className={imageClasses}
                width="120"
                height="120"
              />
            </a>
            <a
              href="https://trello.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={trello_logo}
                alt="..."
                className={imageClasses}
                width="120"
                height="120"
              />
            </a>
            <a
              href="https://www.microsoft.com/en-us/microsoft-365/project/project-management-software"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={ms_project_logo}
                alt="..."
                className={imageClasses}
                width="120"
                height="120"
              />
            </a>
            <div></div>
          </div>
        </row>
      </CardBody>
      <CardFooter className={classes.cardHeader}>
        <p>
          An aeronautical engineer by training, the systems approach runs deep.
        </p>
      </CardFooter>
    </Card>
  );
}

function FullStack() {
  const classes = useStyles();
  // const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <Card>
      <CardHeader color="info" className={classes.cardHeader}>
        <h4 className="card-header-line">Full Stack Tools</h4>
      </CardHeader>
      <CardBody>
        <row>
          <div className="python-line">
            <div></div>
            <a
              href="https://aws.amazon.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={aws_logo}
                alt="..."
                className={imageClasses}
                width="120"
                height="120"
              />
            </a>
            <a
              href="https://www.docker.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={docker_logo}
                alt="..."
                className={imageClasses}
                width="120"
                height="120"
              />
            </a>
            <a
              href="https://aws.amazon.com/cdk/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={cdk_logo}
                alt="..."
                className={imageClasses}
                width="120"
                height="120"
              />
            </a>
            <a
              href="https://nifi.apache.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={nifi_logo}
                alt="..."
                className={imageClasses}
                width="120"
                height="120"
              />
            </a>
            <div></div>
          </div>
        </row>
      </CardBody>
      <CardFooter className={classes.cardHeader}>
        <p>
          So many tools to make life easier. Not sure where else to put NiFi so
          there it is.
        </p>
      </CardFooter>
    </Card>
  );
}

function Databases() {
  const classes = useStyles();
  // const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <Card>
      <CardHeader color="info" className={classes.cardHeader}>
        <h4 className="card-header-line">Databases</h4>
      </CardHeader>
      <CardBody>
        <row>
          <div className="python-line">
            <div></div>
            <a
              href="https://www.elastic.co/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={elastic_logo}
                alt="..."
                className={imageClasses}
                width="120"
                height="120"
              />
            </a>
            <a
              href="https://dgraph.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={dgraph_logo}
                alt="..."
                className={imageClasses}
                width="120"
                height="120"
              />
            </a>
            <a
              href="https://www.mongodb.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={mongodb_logo}
                alt="..."
                className={imageClasses}
                width="120"
                height="120"
              />
            </a>
            <a
              href="https://redis.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={redis_logo}
                alt="..."
                className={imageClasses}
                width="120"
                height="120"
              />
            </a>
            <div></div>
          </div>
        </row>
      </CardBody>
      <CardFooter className={classes.cardHeader}>
        <p>
          Because each database has a purpose.
        </p>
      </CardFooter>
    </Card>
  );
}
