import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

//Images
import rasp_logo from "assets/img/rasp_logo.png";
import kubernetes_logo from "assets/img/kubernetes_logo.png";
import helm_logo from "assets/img/helm_logo.png";
import rust_logo from "assets/img/rust_logo.png";
import golang_logo from "assets/img/golang_logo.png";

// Card Stuff
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

const useStyles = makeStyles(styles);

export default function ProductionSkills() {
  const classes = useStyles();
  return (
    <div>
      <GridContainer justify="center">
        <h3 className={classes.title}>Running on My Home Lab</h3>
      </GridContainer>

      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12} className={classes.navWrapper}>
          <Shtuff />
        </GridItem>
      </GridContainer>
    </div>
  );
}

function Shtuff() {
  const classes = useStyles();
  // const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <Card>
      <CardHeader color="info" className={classes.cardHeader}>
        <h4 className="card-header-line">
          Powered by Pi - Not Pie - Unless We are Talking About Me - Then Yes -
          Powered by Pie
        </h4>
        <a
          href="https://www.raspberrypi.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={rasp_logo}
            className={imageClasses}
            alt="raspberryPiLogo"
            width="160"
            height="160"
          />
        </a>
      </CardHeader>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <CardBody>
            <row>
              <div className="python-line">
                <div></div>
                <a
                  href="https://kubernetes.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={kubernetes_logo}
                    alt="..."
                    className={imageClasses}
                    width="160"
                    height="160"
                  />
                </a>
                <a
                  href="https://helm.sh/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={helm_logo}
                    alt="..."
                    className={imageClasses}
                    width="160"
                    height="160"
                  />
                </a>
                <a
                  href="https://www.rust-lang.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={rust_logo}
                    alt="..."
                    className={imageClasses}
                    width="160"
                    height="160"
                  />
                </a>
                <a
                  href="https://go.dev/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={golang_logo}
                    alt="..."
                    className={imageClasses}
                    width="160"
                    height="160"
                  />
                </a>
                <div></div>
              </div>
            </row>
          </CardBody>
        </GridItem>
      </GridContainer>
      <CardFooter className={classes.cardHeader}>
        <p>For keeping myself out of trouble.</p>
      </CardFooter>
    </Card>
  );
}
